import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import {
    Container,
    Row,
    Col,
    Card,
    Form,
    Button,
} from "react-bootstrap";

const GeneralMessage = () => {
    const location = useLocation();
    const message = location?.state?.message;

    return (
        <Container fluid className="d-flex justify-content-center align-items-center" style={{ height: "70vh" }}>
            <Row>
                <Col></Col>
                <Col xs={10} md={6} className="d-flex align-items-center">
                    <Card className="p-3 w-100">
                        <Card.Body>
                            <Card.Title className="text-left">{message}</Card.Title>
                        </Card.Body>
                    </Card>
                </Col>
                <Col></Col>
            </Row>
        </Container>
    );
};

export default GeneralMessage;
