import { createSlice } from "@reduxjs/toolkit";


export const ppStartPageSlice = createSlice({
  name: "ppStartPage",
  initialState: {
    responseData: {},
  },
  reducers: {
    setResponseData: (state, action) => {
      state.responseData = action.payload;
    },
  },
});

export const { setResponseData } = ppStartPageSlice.actions;

export default ppStartPageSlice.reducer;
