import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit'; // import configureStore function from Redux Toolkit
import ppStartPageSlice from './Components/TestEngine/ppStartPageSlice'; // import your Redux slice

const store = configureStore({
  reducer: {
    ppStartPage: ppStartPageSlice, // add your ppStartPageSlice reducer to the Redux store
  },
});


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  
  <Provider store={store}>
    <App />
  </Provider>,
);

reportWebVitals();
