import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card, Form, Button, Alert } from "react-bootstrap";
import axios from 'axios';
import { useLoading } from "../Static/LoadingContext";

const RetrievePassword = () => {
  const { setIsLoading } = useLoading();
  const [user, setUser] = useState("");
  const [displayMsg, setDisplayMsg] = useState("");
  const [isSubmit, setIsSubmit] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [submitErrors, setSubmitErrors] = useState("");

  const handleChange = (event) => {
    setUser(event.target.value);
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormErrors(validate(user));
    setIsSubmit(true);
    setSubmitErrors("");
  }

  useEffect(() => {
    if (isSubmit && Object.keys(formErrors).length === 0) {
      const data = {
        email_or_username: user
      };
      setDisplayMsg("");
      setIsLoading(true);
      axios.post(process.env.REACT_APP_BACKEND_API_URL + "/forgot-password/", data)
        .then((response) => {
          setDisplayMsg(response.data.message);
          setUser("");
        })
        .catch((error) => {
          console.log(error)
          if (error.response.data.email_or_username[0]){
            setSubmitErrors(error.response.data.email_or_username[0])
          }
          else{
          setSubmitErrors(error.response.data);
          console.log(error);
          }
        })
        .finally(() => {
          setIsSubmit(false);
          setIsLoading(false);
        });
    } else {
      setIsSubmit(false);
    }
  }, [isSubmit, formErrors, user]);

  const validate = (values) => {
    const errors = {};
    if (!values) {
      errors.user = "Field cannot be empty";
    }
    return errors;
  };

  const handleEnterKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleSubmit();
    }
  };

  return (
    <Container fluid style={{ minHeight: "100vh", display: "flex", flexDirection: "column" }}>
      <Row>
        <Col md="3"></Col>
        <Col md="6" className="mt-4">
          <Card className="mt-4" style={{ maxWidth: "600px", margin: "0 auto" }}>
            <Card.Body>
              {displayMsg && <Alert variant="success">{displayMsg}</Alert>}
              {submitErrors && <Alert variant="danger">{submitErrors}</Alert>}
              <h3 className="fw-bold mb-4 pb-2 pb-md-0 mb-md-5">Forgot Password</h3>
              <Row>
                <Col>
                  <Form.Group className="mb-2" controlId="form4">
                    <Form.Label>Enter username or email you registered with</Form.Label>
                    <Form.Control
                      size="md"
                      type="text"
                      value={user}
                      onChange={handleChange}
                      isInvalid={!!formErrors.user}
                      onKeyDown={handleEnterKeyPress}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formErrors.user}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
            </Card.Body>
            <Card.Footer>
              <Row>
                <Button onClick={handleSubmit} className="mb-3 mt-2">
                  Retrieve your Password
                </Button>
              </Row>
            </Card.Footer>
          </Card>
        </Col>
        <Col md="3"> </Col>
      </Row>
    </Container>
  );
};

export default RetrievePassword;
