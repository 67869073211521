import React, { useState, useEffect, useRef } from 'react';
// import { useNavigate } from 'react-router-dom';
import {
  Row,
  Col,
  Card,
  Button,
  Badge,
  Form,
  Modal
} from 'react-bootstrap';
import TimerPage from './TimePage';
import '../CssFiles/PracticeTest.css';
import { Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';

import axios from 'axios';
import { useNavigate } from 'react-router';
import MathJaxComponent from '../Static/MathJaxComponent';
import baseURL from '../../api';


const FetchImageWithHeaders = ({ imageName }) => {
  const [imageSrc, setImageSrc] = useState(null);

  useEffect(() => {
    const fetchImage = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_API_URL}/fetch-image/${imageName}`, {
          method: 'GET',
          headers: {
            'Authorization': `Token ${localStorage.getItem("authToken")}`, // Add your required headers here
          },
        });

        if (response.ok) {
          const blob = await response.blob();
          const url = URL.createObjectURL(blob);
          setImageSrc(url);
        } else {
          console.error('Failed to fetch image:', response.statusText);
        }
      } catch (error) {
        console.error('Error fetching image:', error);
      }
    };

    fetchImage();
  }, [imageName]);

  if (!imageSrc) {
    return null; // or a loading spinner
  }

  return (
    <Row>
      <Col md={2}></Col>
      <Col md={6}>
        <Zoom>
          <div style={{ maxHeight: '40vh' }}>
            <img src={imageSrc} alt={imageName} />
          </div>
        </Zoom>
      </Col>
      <Col md={4}></Col>
    </Row>
  );
};

export default function PracticeTest(props) {

  const [responseData, setResponseData] = useState(null);

  const [questionLength, setQuestionLength] = useState(null);
  const token = localStorage.getItem("authToken")
  const config = {
    headers: { Authorization: `Token ${localStorage.getItem("authToken")}` }
  };
  // const responseData = useSelector((state) => state.ppStartPage.responseData);
  const [answers, setAnswers] = useState({});
  const [currentQuestion, setCurrentQuestion] = useState({});
  const [flaggedQuestions, setFlaggedQuestions] = useState([]);
  const [id, setId] = useState(0)
  const [questions, setQuestions] = useState([]);
  const currentQuestionIndex = questions.findIndex((question) => question.id === currentQuestion.id);
  const [answersCount, setAnswersCount] = useState(0);
  const [totalAnswersCount, setTotalAnswersCount] = useState(0);

  const [isRunning, setIsRunning] = useState(true);

  useEffect(() => {
    const storedData = localStorage.getItem("responseData");
    if (storedData) {
      setResponseData(JSON.parse(storedData));
    }
    // // console.log("response",responseData)

  }, []);



  const [timeLeft, setTimeLeft] = useState(() => {

    const storedData = localStorage.getItem("responseData");
    const storedTimeLeft = JSON.parse(storedData).total_time
    // console.log("storedTime",storedTimeLeft)

    const initialTime = storedTimeLeft ? parseInt(storedTimeLeft, 10) : questions.length * 60;
    // console.log("initial time",initialTime)

    return initialTime;

  });

  useEffect(() => {
    if (responseData && responseData.questions && responseData.questions.length > 0) {
      setQuestions(responseData.questions);
      setId(responseData.id)
      setQuestionLength(responseData.questions.length)
    }

  }, [responseData]);
  // console.log(questionLength);
  const totalQuestions = questions.length;
  const navigate = useNavigate();

  useEffect(() => {
    if (questions && questions.length > 0)
      setCurrentQuestion(questions[0]);
  }, [questions]);



  useEffect(() => {
    const previousData = JSON.parse(localStorage.getItem('data')) || {};
    const currentQuestionData = previousData[currentQuestion?.id];
    if (currentQuestionData) {
      setAnswers(currentQuestionData.answers);

    } else {
      setAnswers({});

    }
  }, [currentQuestion]);


  useEffect(() => {
    setAnswersCount(Object.values(answers).reduce((count, value) => {
      return count + (typeof value === 'string' && value.trim() !== '' ? 1 : 0);
    }, 0));
  }, [answers]);

  useEffect(() => {
    localStorage.setItem("timeLeft", timeLeft.toString());
    if (isRunning && timeLeft > 0) {
      const timerId = setTimeout(() => setTimeLeft(timeLeft - 1), 1000);
      return () => clearTimeout(timerId);
    } else if (timeLeft === 0) {
      handlesubmit();
      setIsRunning(false);
    }
  }, [timeLeft, isRunning]);

  const saveExamState = () => {
    const data = {
      exam_id: id,
      answers: JSON.parse(localStorage.getItem('data')),
      reviewed_questions: flaggedQuestions.map((question) => question.id),
    };

    const config = {
      headers: {
        Authorization: `Token ${localStorage.getItem("authToken")}`,
      },
    };

    axios
      .post(baseURL + "/practice-test-state-save/", data, config)
      .then((response) => {
        // console.log("Exam state saved successfully:", response);
      })
      .catch((error) => {
        console.error("Error saving exam state:", error);
      });
  };



  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (type === "radio") {
      if (checked) {
        setAnswers(prevAnswers => {
          return {
            ...prevAnswers,
            [name]: value
          };
        });
      }
    } else {
      setAnswers((prevAnswers) => ({
        ...prevAnswers,
        [name]: value,
      }));
    }
    const allAnswers = Object.values({
      ...answers,
      [name]: value
    });
    const newTotalAnswersCount = allAnswers.reduce((count, value) => {
      return count + (typeof value === 'string' && value.trim() !== '' ? 1 : 0);
    }, 0);
    setAnswersCount(newTotalAnswersCount);

    // Update the total answer count
    setTotalAnswersCount(prevTotalAnswersCount => {
      return prevTotalAnswersCount + (newTotalAnswersCount - answersCount);
    });
  };



  const handleNext = () => {

    const currentQuestionIndex = questions.findIndex((question) => question.id === currentQuestion?.id);
    if (currentQuestionIndex === -1) {
      // handle error or redirect to default question
    } else if (currentQuestionIndex < questions.length - 1) {
      const nextQuestion = questions[currentQuestionIndex + 1];
      setCurrentQuestion(nextQuestion);
    } else {
      setCurrentQuestion(questions[0]);
    }
    const data = {
      question: currentQuestion.id,
      answers: answers

    };
    const previousData = JSON.parse(localStorage.getItem('data')) || {};
    const mergedData = {
      ...previousData,
      [currentQuestion.id]: data
    };
    localStorage.setItem('data', JSON.stringify(mergedData));
    const previousAnswer = previousData[currentQuestion.id]?.answers;
    if (previousAnswer) {
      setAnswers(previousAnswer);
    } else {
      setAnswers({});
    }
    saveExamState(); // Save state before moving to next question
  }


  const handlePrev = () => {

    const currentQuestionIndex = questions.findIndex((question) => question.id === currentQuestion?.id);
    if (currentQuestionIndex > 0) {
      const previousQuestion = questions[currentQuestionIndex - 1];
      setCurrentQuestion(previousQuestion);
    }

    const data = {
      question: currentQuestion.id,
      answers: answers
    };

    const previousData = JSON.parse(localStorage.getItem('data')) || {};
    const mergedData = {
      ...previousData,
      [currentQuestion.id]: data
    };
    localStorage.setItem('data', JSON.stringify(mergedData));

    const previousAnswer = previousData[currentQuestion.id]?.answers;
    if (previousAnswer) {
      setAnswers(previousAnswer);
    } else {
      setAnswers({});
    }
    saveExamState(); // Save state before moving to next question

  }

  const handleQuestionClick = (question) => {

    const data = {
      question: currentQuestion.id,
      answers: answers
    };

    const previousData = JSON.parse(localStorage.getItem('data')) || {};
    const mergedData = {
      ...previousData,
      [currentQuestion.id]: data
    };
    localStorage.setItem('data', JSON.stringify(mergedData));

    if (question.id === currentQuestion.id) {
      setCurrentQuestion(question);
      return;
    }

    const previousAnswer = previousData[question.id]?.answers;
    if (previousAnswer) {
      setAnswers(previousAnswer);
    } else {
      setAnswers({});
    }
    setCurrentQuestion(question);
    saveExamState(); // Save state before moving to next question
  };

  const handleSkip = (question) => {
    if (flaggedQuestions.includes(question)) {
      setFlaggedQuestions(flaggedQuestions.filter(q => q !== question));
    } else {
      setFlaggedQuestions([...flaggedQuestions, question]);
    }
    saveExamState();
  };

  function getQuestionItemClassName(question) {
    const data = JSON.parse(localStorage.getItem('data')) || {};
    const currentQuestionData = data[question.id];
    const answer = currentQuestionData ? currentQuestionData.answers : null;
    const hasAnswer = answer ? Object.values(answer).some(value => typeof value === 'string' && value.trim() !== '') : false;
    const isFlagged = flaggedQuestions.includes(question);

    if (isFlagged) {
      return "questionid questionnumbercolor";
    } else if (hasAnswer) {
      return "questionid answered";
    } else {
      return "questionid";
    }
  }


  function handlesubmit(e) {
    const data = {
      question: currentQuestion.id,
      answers: answers
    };
  
    const previousData = JSON.parse(localStorage.getItem('data')) || {};
    const mergedData = {
      ...previousData,
      [currentQuestion.id]: data
    };
    localStorage.setItem('data', JSON.stringify(mergedData));
    const previousAnswer = previousData[currentQuestion.id]?.answers;
    if (previousAnswer) {
      setAnswers(previousAnswer);
    } else {
      setAnswers({});
    }
  
    const userAnswers = {};
    const questionsData = [];
  
    questions.forEach((question, index) => {
      const questionData = {
        id: question.id,
        questionDescription: question.questionDescription,
        image: null,
        question_type: question.question_type,
      };
  
      if (question.question_type === "MC") {
        questionData.ansOption1 = question.ansOption1 || null;
        questionData.ansOption2 = question.ansOption2 || null;
        questionData.ansOption3 = question.ansOption3 || null;
        questionData.ansOption4 = question.ansOption4 || null;
        questionData.ansOption5 = question.ansOption5 || null;
        questionData.ansOption6 = question.ansOption6 || null;
        userAnswers[question.id] = mergedData[question.id]?.answers || "";
      } else if (question.question_type === "MP") {
        questionData.fib_subquestions = question.fib_subquestions || [];
        questionData.mc_subquestions = question.mc_subquestions || [];
  
        question.fib_subquestions.forEach((subQuestion, subIndex) => {
          console.log("FIB subQuestion", subQuestion)
          console.log("mergedData", mergedData)
          const subAnswerKey = `${subQuestion.id}`;
          userAnswers[subAnswerKey] = mergedData[question.id]?.answers[subAnswerKey] || "";
        });
  
        question.mc_subquestions.forEach((subQuestion, subIndex) => {
          console.log("MC subQuestion", subQuestion)
          const subAnswerKey = `${subQuestion.id}`;
          userAnswers[subAnswerKey] = mergedData[question.id]?.answers[subAnswerKey] || "";
        });
      } else {
        userAnswers[question.id] = mergedData[question.id]?.answers || "";
      }
  
      questionsData.push(questionData);
    });
  
    const token = localStorage.getItem("authToken");
    const config = {
      headers: {
        Authorization: `Token ${localStorage.getItem("authToken")}`,
      },
    };
  
    const submitData = {
      id: id,
      questions: questionsData,
      user_answers: userAnswers
    };
  
    axios
      .post(process.env.REACT_APP_BACKEND_API_URL + "/test-submit/", submitData, config)
      .then((response) => {
        navigate('/testresults', { state: submitData });
        localStorage.removeItem("data");
        localStorage.removeItem("responseData");
        localStorage.removeItem("timeLeft");
      })
      .catch((error) => {
        console.log(error);
      });
  }
  
  

  const disableCopying = (e) => {
    e.preventDefault();
    return false;
  };

  const [showModal, setShowModal] = useState(false);

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  return (
    <div style={{ minHeight: "100vh", backgroundColor: "#E8E8E8", userSelect: "none" }}
      onContextMenu={disableCopying}
      onCopy={disableCopying}
      onCut={disableCopying}
      onPaste={disableCopying}
    >

      <Row>
        <Col md={1}></Col>
        <Col md={7}>
          <Card className="mt-2" >
            <Card.Header as="h5" style={{ backgroundColor: "#3366FF", color: "#FFFFFF" }}>Math Practice</Card.Header>
            <Card.Body style={{ overflowY: "auto", maxHeight: "calc(100vh - 300px)" }}>
              <Row>
                {currentQuestion && (currentQuestion.question_type !== "MP") && (
                  <div style={{ position: 'relative' }}>
                            <Badge style={{ position: 'absolute', top: 5, right: 10 }} bg="info">{currentQuestion.score} {currentQuestion.score < 2 ? 'mark' : 'marks'}</Badge>
                    <Card.Title>Question No. {currentQuestionIndex + 1}</Card.Title>
                  </div>
                )}

                {currentQuestion && (currentQuestion.question_type === "MP") && (
                  <div style={{ position: 'relative' }}>
                    <Card.Title>Question No. {currentQuestionIndex + 1}</Card.Title>
                  </div>
                )}
              </Row>
              <Row>
                <Form.Group>
                  {currentQuestion && (
                    <Card.Text>
                      <div style={{ width: '100%' }}>
                        <MathJaxComponent>{currentQuestion.questionDescription}</MathJaxComponent>
                        {currentQuestion.question_type === "MC" && (

                          <Row className='m-2'>
                            {currentQuestion.image && (
                              <FetchImageWithHeaders imageName={currentQuestion.image} />
                            )}
                          </Row>
                        )}
                        {currentQuestion.question_type === "MC" && (
                          <Row style={{ marginLeft: '20px' }}>
                            <Col style={{ height: "20vh", overflowY: "auto" }}>
                              <Row>
                                {currentQuestion.ansOption1 && (
                                  <Form.Check
                                    type="radio"
                                    label={currentQuestion.ansOption1}
                                    id={`option1-${currentQuestion.id}`}
                                    name={currentQuestion.id}
                                    value={currentQuestion.ansOption1}
                                    onChange={handleChange}
                                    checked={answers[currentQuestion.id] === currentQuestion.ansOption1}
                                  />
                                )}
                              </Row>
                              <Row>
                                {currentQuestion.ansOption2 && (
                                  <Form.Check
                                    type="radio"
                                    label={currentQuestion.ansOption2}
                                    id={`option2-${currentQuestion.id}`}
                                    name={currentQuestion.id}
                                    value={currentQuestion.ansOption2}
                                    onChange={handleChange}
                                    checked={answers[currentQuestion.id] === currentQuestion.ansOption2}
                                  />
                                )}
                              </Row>
                              <Row>
                                {currentQuestion.ansOption3 && (
                                  <Form.Check
                                    type="radio"
                                    label={currentQuestion.ansOption3}
                                    id={`option3-${currentQuestion.id}`}
                                    name={currentQuestion.id}
                                    value={currentQuestion.ansOption3}
                                    onChange={handleChange}
                                    checked={answers[currentQuestion.id] === currentQuestion.ansOption3}
                                  />
                                )}
                              </Row>
                            </Col>
                            <Col>
                              <Row>
                                {currentQuestion.ansOption4 && (
                                  <Form.Check
                                    type="radio"
                                    label={currentQuestion.ansOption4}
                                    id={`option4-${currentQuestion.id}`}
                                    name={currentQuestion.id}
                                    value={currentQuestion.ansOption4}
                                    onChange={handleChange}
                                    checked={answers[currentQuestion.id] === currentQuestion.ansOption4}
                                  />
                                )}
                              </Row>
                              <Row>
                                {currentQuestion.ansOption5 && (
                                  <Form.Check
                                    type="radio"
                                    label={currentQuestion.ansOption5}
                                    id={`option5-${currentQuestion.id}`}
                                    name={currentQuestion.id}
                                    value={currentQuestion.ansOption5}
                                    onChange={handleChange}
                                    checked={answers[currentQuestion.id] === currentQuestion.ansOption5}
                                  />
                                )}
                              </Row>
                              <Row>
                                {currentQuestion.ansOption6 && (
                                  <Form.Check
                                    type="radio"
                                    label={currentQuestion.ansOption6}
                                    id={`option6-${currentQuestion.id}`}
                                    name={currentQuestion.id}
                                    value={currentQuestion.ansOption6}
                                    onChange={handleChange}
                                    checked={answers[currentQuestion.id] === currentQuestion.ansOption6}
                                  />
                                )}
                              </Row>
                            </Col>
                          </Row>
                        )}
                        {currentQuestion.question_type === "FIB" && (
                          <>

                            <Row className='m-2'>
                              {currentQuestion.image && (
                                <FetchImageWithHeaders imageName={currentQuestion.image} />
                              )}
                            </Row>

                            <Row className="align-items-center"> {/* Center align input */}
                              <Col>
                                <span>{currentQuestion.answerPrefix}</span> {/* Display prefix */}
                                <input
                                  className="mt-4"
                                  style={{
                                    width: "70%",
                                    borderTop: "none",
                                    borderLeft: "none",
                                    borderRight: "none",
                                    borderBottom: "1px solid black",
                                    outline: "none"
                                  }}
                                  key={currentQuestion.id}
                                  name={currentQuestion.id}
                                  type="text"
                                  placeholder="Enter your answer here"
                                  value={answers[currentQuestion.id] || ""}
                                  onChange={handleChange}
                                />
                                <span>{currentQuestion.answerSuffix}</span> {/* Display suffix */}
                              </Col>
                            </Row>
                          </>

                          // <Row>
                          //   <Col>
                          //     <input
                          //       className="mt-4"
                          //       style={{
                          //         width: "70%",
                          //         borderTop: "none",
                          //         borderLeft: "none",
                          //         borderRight: "none",
                          //         borderBottom: "1px solid black",
                          //         outline: "none",
                          //       }}
                          //       key={currentQuestion.id}
                          //       name={currentQuestion?.id}
                          //       type="text"
                          //       placeholder="Enter your answer here"
                          //       value={answers[currentQuestion?.id] || ""}
                          //       onChange={handleChange}
                          //     />
                          //   </Col>
                          // </Row>
                          
                        )}
                        {currentQuestion.question_type === "MP" && (
                          <>
                        {currentQuestion.fib_subquestions && currentQuestion.fib_subquestions.map((subQuestion, index) => (
                          <div key={`${index}`} style={{ border: '1px solid #007bff', padding: '10px', borderRadius: '5px', marginTop: '10px', position: 'relative' }}>
                            <strong>Sub Question {index + 1}: </strong> <br/>
                            <Badge style={{ position: 'absolute', top: 10, right: 10 }} bg="info">{subQuestion.score} {subQuestion.score < 2 ? 'mark' : 'marks'}</Badge>
                            <div> 
                              
                            <MathJaxComponent>{subQuestion.questionDescription}</MathJaxComponent>
                            </div>
                            <Row className="align-items-center">
                              <Col>
                                <span>{subQuestion.answerPrefix}</span>
                                <input
                                  className="mt-4"
                                  style={{
                                    width: "70%",
                                    borderTop: "none",
                                    borderLeft: "none",
                                    borderRight: "none",
                                    borderBottom: "1px solid black",
                                    outline: "none"
                                  }}
                                  key={`${subQuestion.id}`}
                                  name={`${subQuestion.id}`}
                                  type="text"
                                  placeholder="Enter your answer here"
                                  value={answers[`${subQuestion.id}`] || ""}
                                  onChange={handleChange}
                                />
                                <span>{subQuestion.answerSuffix}</span>
                              </Col>
                            </Row>
                          </div>
                        ))}

                        {currentQuestion.mc_subquestions && currentQuestion.mc_subquestions.map((subQuestion, index) => (
                          <div key={`${index}`} style={{ border: '1px solid #007bff', padding: '10px', borderRadius: '5px', marginTop: '10px', position: 'relative' }}>
                            <Badge style={{ position: 'absolute', top: 10, right: 10 }} bg="info">{subQuestion.score} {subQuestion.score < 2 ? 'mark' : 'marks'}</Badge>
                            <div> <strong>Sub Question {index + 1 + currentQuestion.fib_subquestions.length}: </strong><br/>
                            <MathJaxComponent>{subQuestion.questionDescription}</MathJaxComponent>
                            </div>
                            <Row style={{ marginLeft: '20px' }}>
                              <Col style={{ height: "20vh", overflowY: "auto" }}>
                                <Row>
                                  {subQuestion.ansOption1 && (
                                    <Form.Check
                                      type="radio"
                                      label={subQuestion.ansOption1}
                                      id={`option1-${subQuestion.id}`}
                                      name={`${subQuestion.id}`}
                                      value={subQuestion.ansOption1}
                                      onChange={handleChange}
                                      checked={answers[`${subQuestion.id}`] === subQuestion.ansOption1}
                                    />
                                  )}
                                </Row>
                                <Row>
                                  {subQuestion.ansOption2 && (
                                    <Form.Check
                                      type="radio"
                                      label={subQuestion.ansOption2}
                                      id={`option2-${subQuestion.id}`}
                                      name={`${subQuestion.id}`}
                                      value={subQuestion.ansOption2}
                                      onChange={handleChange}
                                      checked={answers[`${subQuestion.id}`] === subQuestion.ansOption2}
                                    />
                                  )}
                                </Row>
                                <Row>
                                  {subQuestion.ansOption3 && (
                                    <Form.Check
                                      type="radio"
                                      label={subQuestion.ansOption3}
                                      id={`option3-${subQuestion.id}`}
                                      name={`${subQuestion.id}`}
                                      value={subQuestion.ansOption3}
                                      onChange={handleChange}
                                      checked={answers[`${subQuestion.id}`] === subQuestion.ansOption3}
                                    />
                                  )}
                                </Row>
                              </Col>
                              <Col>
                                <Row>
                                  {subQuestion.ansOption4 && (
                                    <Form.Check
                                      type="radio"
                                      label={subQuestion.ansOption4}
                                      id={`option4-${subQuestion.id}`}
                                      name={`${subQuestion.id}`}
                                      value={subQuestion.ansOption4}
                                      onChange={handleChange}
                                      checked={answers[`${subQuestion.id}`] === subQuestion.ansOption4}
                                    />
                                  )}
                                </Row>
                                <Row>
                                  {subQuestion.ansOption5 && (
                                    <Form.Check
                                      type="radio"
                                      label={subQuestion.ansOption5}
                                      id={`option5-${subQuestion.id}`}
                                      name={`${subQuestion.id}`}
                                      value={subQuestion.ansOption5}
                                      onChange={handleChange}
                                      checked={answers[`${subQuestion.id}`] === subQuestion.ansOption5}
                                    />
                                  )}
                                </Row>
                                <Row>
                                  {subQuestion.ansOption6 && (
                                    <Form.Check
                                      type="radio"
                                      label={subQuestion.ansOption6}
                                      id={`option6-${subQuestion.id}`}
                                      name={`${subQuestion.id}`}
                                      value={subQuestion.ansOption6}
                                      onChange={handleChange}
                                      checked={answers[`${subQuestion.id}`] === subQuestion.ansOption6}
                                    />
                                  )}
                                </Row>
                              </Col>
                            </Row>
                          </div>
                        ))}
                        </> 
                        )}

                      </div>
                    </Card.Text>
                  )}
                </Form.Group>
              </Row>
            </Card.Body>
            <Card.Footer style={{ position: "fixed", bottom: "0", left: "0", right: "0", width: "calc(100% - 30px)", margin: "auto auto" }}>

              <Row>
                <Col md={4}>
                  {currentQuestion && (
                    <Button variant='warning' onClick={() => handleSkip(currentQuestion)}>Mark for review</Button>
                  )}
                </Col>
                <Col md={2}>
                  <Button variant='primary' onClick={handlePrev}>{"<< "}Prev</Button>
                </Col>
                <Col md={2}>
                  {currentQuestion && (
                    <Button variant='primary' onClick={handleNext}>Next{" >>"}</Button>
                  )}
                </Col>
                <Col md={1}></Col>
                <Col md={3}>
                  {/* <Button variant='success' onClick={handlesubmit}>Submit test</Button> */}
                  <Button variant='success' onClick={handleShowModal}>Submit test</Button>
                </Col>
              </Row>
            </Card.Footer>
          </Card>
        </Col>
        <Col md={4}>
          <Card className="mt-2">
            <Card.Header as="h5" style={{ backgroundColor: "#CCFFFF", color: "Black" }}>
              <Form.Label>Time remaining:&nbsp;</Form.Label>
              <TimerPage timeLeft={timeLeft} isRunning={isRunning} />
            </Card.Header>
            <Card.Body>
              {/* <Card.Subtitle>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer sollicitudin pellentesque quam et molestie. Duis vel maximus nisl, ac suscipit nunc
          </Card.Subtitle> */}
              <ul className="question-list mt-4">
                {questions && questions.map((question, index) => {
                  const questionItemClassName = getQuestionItemClassName(question);
                  const isFlagged = flaggedQuestions.includes(question);
                  const isCurrentQuestion = index === currentQuestionIndex;

                  return (
                    <div
                      key={question.id}
                      className={`question-item ${questionItemClassName} ${isFlagged ? 'questionnumbercolor' : ''} ${isCurrentQuestion ? 'current-question-color' : ''}`}
                      onClick={() => {
                        handleQuestionClick(question, index);
                      }}
                    >
                      {index + 1}
                    </div>
                  );
                })}
              </ul>
            </Card.Body>
            <Card.Footer>
              <Row className="mt-2">
                <Col>
                  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Badge bg="warning" style={{ fontSize: "15px", display: "flex", width: "50px", height: "50px", alignItems: "center", justifyContent: "center" }}>
                      {flaggedQuestions.length}
                    </Badge>
                    <div style={{ fontSize: "14px" }}>To be reviewed</div>
                  </div>
                </Col>
                <Col>
                  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Badge bg="primary" style={{ fontSize: "15px", display: "flex", width: "50px", height: "50px", alignItems: "center", justifyContent: "center" }}>
                      {totalAnswersCount}
                    </Badge>
                    <div style={{ fontSize: "14px" }}>Answered</div>
                  </div>
                </Col>
                <Col>
                  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Badge bg="danger" style={{ fontSize: "15px", display: "flex", width: "50px", height: "50px", alignItems: "center", justifyContent: "center" }}>
                      {totalQuestions - totalAnswersCount}
                    </Badge>
                    <div style={{ fontSize: "14px" }}>Unanswered</div>
                  </div>
                </Col>
              </Row>
            </Card.Footer>
          </Card>
        </Col>
      </Row>
                

      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Submit Exam</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to submit the exam? You cannot make any changes after submission.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleCloseModal}>
            No, Go Back
          </Button>
          <Button variant="success" onClick={handlesubmit}>
            Yes, Submit
          </Button>
        </Modal.Footer>
      </Modal>

    </div>
  )
}
