import React, { useState } from "react";
import MCForm from "./MCForm";
import FIBForm from "./FIBForm";
import { Row, Col, Form, Button } from "react-bootstrap";
// import "./MPQ.css"
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Metadata from "./MetaData";
import axios from "axios";
import { json } from "react-router";

function MultipartQuestionForm(props) {
  const [mainQuestion, setMainQuestion] = useState("");
  const [subQuestions, setSubQuestions] = useState([]);
  const subject = props. subject;
  const addMCQuestion = () => {
    setSubQuestions([...subQuestions, {
      type: "MC",
      questionDescription: "",
      ansOption1: "",
      ansOption2: "",
      ansOption3: "",
      ansOption4: "",
      ansOption5: "",
      ansOption6: "",
      checkbox1: false,
      checkbox2: false,
      checkbox3: false,
      checkbox4: false,
      checkbox5: false,
      checkbox6: false,
      correctAnswer: "",
      answerExplanation: "",
    }]);
  };

  
  const addFIBQuestion = () => {
    setSubQuestions([...subQuestions, {
      type: "FIB",
      image:"",
      firsthalf:"",
      secondhalf:"",    
      correctAnswer: "",
      answerExplanation: "",
    }]);
  };


  const handleChanges = (index, name, value, checked, type, id) => {
    const updatedSubquestions = [...subQuestions];
    if (name === "questionDescription") {
      updatedSubquestions[index][name] = value;
    } else if (name === "answerExplanations") {
      updatedSubquestions[index][name] = value;
    } else if (name === "ansOption1") {
      updatedSubquestions[index][name] = value;
    } else if (name === "ansOption2") {
      updatedSubquestions[index][name] = value;
    } else if (name === "ansOption3") {
      updatedSubquestions[index][name] = value;
    } else if (name === "ansOption4") {
      updatedSubquestions[index][name] = value;
    } else if (name === "ansOption5") {
      updatedSubquestions[index][name] = value;
    } else if (name === "ansOption6") {
      updatedSubquestions[index][name] = value;
    } else if (type === "checkbox") {
      setSubQuestions((prevChecked) => ({
        ...prevChecked,
        [name]: checked,
      }));
      const newCorrectAnswer = [...subQuestions[index].correctAnswer];
      const value = index;
      if (checked) {
        // console.log(index)
        newCorrectAnswer.push(subQuestions[index]["ansOption" + id]);
      } else {
        
        const index = newCorrectAnswer.indexOf(subQuestions[value]["ansOption" + id]);
        if (index !== -1) {
          newCorrectAnswer.splice(index, 1);
        }
      }
      updatedSubquestions[index].correctAnswer =  newCorrectAnswer;
    }else if(name === "answer"){
      updatedSubquestions[index][name] = value;
    }else if(name === "answerExplanation"){
      updatedSubquestions[index][name] = value;
    }
    setSubQuestions(updatedSubquestions);
  };

  const [topics, setTopics] = useState([]);
  const [mcMetadata, setMCMetadata] = useState({
    dataSource: "",
    sourceYear: "",
    difficultyLevel: "",
    academicYear: "",
  });
  const handleTopicChange = (event) => {
    const topic = event.target.value;
    const isChecked = event.target.checked;
    if (isChecked) {
      setTopics([...topics, topic]);
    } else {
      setTopics(topics.filter((t) => t !== topic));
    }
  };


  const handleChange = (event) => {
    const { name, value, id } = event.target;

  if (id === "topics") {
      const topic = event.target.value;
      const isChecked = event.target.checked;
      if (isChecked) {
        setTopics([...topics, topic]);
      } else {
        setTopics(topics.filter((t) => t !== topic));
      }
    }
    else {
      setMCMetadata((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    
    const data = {
      questionDescription:mainQuestion,
      difficultyLevel: mcMetadata.difficultyLevel,
      academicYear: mcMetadata.academicYear,
      sourceYear: mcMetadata.sourceYear,
      topic: topics,
      sub_questions: subQuestions.map((question) => ({
        ...question,
        correctAnswer: question.correctAnswer.join(""), // convert the correct answer from array to string
      })),
    }
        axios
              .post(process.env.REACT_APP_BACKEND_API_URL + "/MathsMP/create/",data)
              .then((response) => {
                // console.log(response);
              })
              .catch((error) => {
                // console.log(error);
              });
  };

  return (
    <Form onSubmit={handleSubmit}>
      <h4 htmlFor="mainQuestion">Main Question:</h4>
      <ReactQuill
        rows={4}
        cols={60}
        id="mainQuestion"
        type="text"
        value={mainQuestion}
        onChange={(value) => setMainQuestion(value)}
      />
      {mainQuestion}
      <hr className="mt-3"></hr>
    <div className="sub-questions-container">
      {subQuestions && subQuestions.map((subQuestion, index) => (

        <div key={index+1} >
          {subQuestion.type === "MC" && (
            <MCForm
              key={index}
              index={index}
              propvalues={subQuestions}
              subject = {subject}
              handleChanges={(e) =>
                handleChanges(index, e.target.name, e.target.value, e.target.checked, e.target.type, e.target.id)
              }
            />
          )}
          {subQuestion.type === "FIB" && (
            <FIBForm
              key={index}
              index={index}
              propvalues={subQuestions}
              subject={subject}
              handleChanges={(e) =>
                handleChanges(index, e.target.name, e.target.value, e.target.checked, e.target.type, e.target.id)
              }
            />
          )}
        </div>
       
      ))}
      <hr className="mt-3"></hr>
      <Row>
        <Col md={5}>
          <Button type="button" onClick={addMCQuestion}>
            Add MC Question
          </Button>
        </Col>
        <Col md={5}>
          <Button type="button" onClick={addFIBQuestion}>
            Add FIB Question
          </Button>
        </Col>
      </Row>
      </div>
      <Metadata onInputChange={handleChange} topics={topics} handleTopicChange={handleTopicChange} subject={subject} />
      <Row className="mt-2">
        <Button onClick={handleSubmit} type="submit">Submit</Button>
      </Row>
     
    </Form>
    
  );
}

export default MultipartQuestionForm;

