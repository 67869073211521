import { Card, Col, ListGroup, ListGroupItem } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";

function TickCard(props) {
    const cardStyle = {
      display: "flex",
      alignItems: "center",
      lineHeight: "1.5rem", // Increase line-height to cover all lines of text
      marginBottom: "20px"
    };
    const text = props.text;
    const textBold = props.textBold;
    const color = props.color;
    const icon = props.icon || faCheckCircle;
  
    return (
      <div style={cardStyle}>
        <FontAwesomeIcon style={{color: color}} icon={icon}  size="2x" />
        <span style={{ marginLeft: "10px", fontWeight: "bold" }}>{textBold}</span>
        <span style={{}}>{text}</span>
      </div>
    );
  }
  
export default TickCard