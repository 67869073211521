import React, { useState, useEffect } from 'react';
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { Row, Form, Modal } from 'react-bootstrap';
import axios from 'axios';
import MathJaxComponent from '../Static/MathJaxComponent';

export default function MathsQuestions() {
    const [MQInput, setMQInput] = useState({ subject: "Maths", choice: "" });
    const [QBData, setQBData] = useState([]);
    const [fibData, setFibData] = useState([]);
    const [mcqData, setMcqData] = useState([]);
    const [modalImage, setModalImage] = useState('');
    const [showModal, setShowModal] = useState(false);


    const handleImageClick = (imageUrl) => {
        setModalImage(imageUrl);
        setShowModal(true);
    };

    const handleClose = () => setShowModal(false);

    useEffect(() => {
        ChangeSubject("Maths");
    }, []);

    const ChangeSubject = (subject) => {
        const token = localStorage.getItem("authToken");
        const config = {
            headers: { Authorization: `Token ${token}` }
        };

        let url = "";
        switch (subject) {
            case "Maths":
                url = process.env.REACT_APP_BACKEND_API_URL + "/get-maths-data/";
                break;
            case "English":
                url = process.env.REACT_APP_BACKEND_API_URL + "/get-english-data/";
                break;
            case "VR":
                url = process.env.REACT_APP_BACKEND_API_URL + "/get-vr-data/";
                break;
            case "NVR":
                url = process.env.REACT_APP_BACKEND_API_URL + "/get-nvr-data/";
                break;
            default:
                return;
        }

        axios.get(url, config)
            .then((response) => {
                const data = response.data;
                const fibQuestions = data.filter(item => item.questionType === 'FIB');
                const mcqQuestions = data.filter(item => item.questionType === 'MCQ');
                setQBData(data);
                setFibData(fibQuestions);
                setMcqData(mcqQuestions);
            });
    };

    const handleChoiceChange = (e) => {
        const { name, value } = e.target;
        setMQInput((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    };

    const columns1 = [
        {
            dataField: "id",
            text: "ID",
            sort: true,
            editable: false,
        },
        {
            dataField: "questionDescription",
            text: "Question Description",
            sort: false,
            editable: false,
            formatter: (cell) => (
                <MathJaxComponent>
                    {cell}
                </MathJaxComponent>
            )
        },
        {
            dataField: "image",
            text: "Image",
            sort: false,
            editable: false,
            formatter: (cell) => (
                cell ? (
                    <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <img src={`${process.env.REACT_APP_BACKEND_API_URL}${cell}`} alt="Question related" style={{ maxWidth: '100%', maxHeight: '100%', }} onClick={() => handleImageClick(`${process.env.REACT_APP_BACKEND_API_URL}${cell}`)} />
                    </div>
                ) : null
            )
        },



    ];

    const columns2 = [
        {
            dataField: "id",
            text: "ID",
            sort: true,
            editable: false,
        },
        {
            dataField: "questionDescription",
            text: "Question Description",
            sort: false,
            editable: false,
            formatter: (cell) => (
                <MathJaxComponent>
                    {cell}
                </MathJaxComponent>
            )
        },

        {
            dataField: "ansOption1",
            text: "Option 1",
            sort: false,
            editable: false,
        },
        {
            dataField: "ansOption2",
            text: "Option 2",
            sort: false,
            editable: false,
        },
        {
            dataField: "ansOption3",
            text: "Option 3",
            sort: false,
            editable: false,
        },
        {
            dataField: "ansOption4",
            text: "Option 4",
            sort: false,
            editable: false,
        },
        {
            dataField: "ansOption5",
            text: "Option 5",
            sort: false,
            editable: false,
        },
        {
            dataField: "ansOption6",
            text: "Option 6",
            sort: false,
            editable: false,
        },
        {
            dataField: "image",
            text: "Image",
            sort: false,
            editable: false,
            formatter: (cell) => (
                cell ? (
                    <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <img src={`${process.env.REACT_APP_BACKEND_API_URL}${cell}`} alt="Question related" style={{ maxWidth: '100%', maxHeight: '100%', }} onClick={() => handleImageClick(`${process.env.REACT_APP_BACKEND_API_URL}${cell}`)} />
                    </div>
                ) : null
            )
        },
    ];

    return (
        <div>
            <h3>View Maths Subject Questions Here</h3>
            <Form.Group controlId="choice">
                <Form.Select
                    style={{ fontSize: "14px" }}
                    onChange={(e) => handleChoiceChange(e)}
                    name="choice"
                >
                    <option value="">Select Question Type</option>
                    <option value="table1">FIB Questions</option>
                    <option value="table2">MCQ Questions</option>
                </Form.Select>
            </Form.Group>
            <Row style={{ margin: "10px" }}>
                <div className="mt-4">
                    <div className="mt-4">
                        {MQInput.choice === "table1" && (
                                <BootstrapTable
                                    keyField="id"
                                    data={fibData}
                                    columns={columns1}
                                    striped
                                    hover

                                    pagination={paginationFactory({ sizePerPage: 5 })}
                                />
                        )}
                        {MQInput.choice === "table2" && (
                                <BootstrapTable
                                    keyField="id"
                                    data={mcqData}
                                    columns={columns2}
                                    striped
                                    hover

                                    pagination={paginationFactory({ sizePerPage: 5 })}

                                />
                        )}
                    </div>
                </div>
            </Row>
            <Modal show={showModal} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Image</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Modal.Body style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <img src={modalImage} alt="Expanded" style={{ width: '100%', height: '100%', }} />
                    </Modal.Body>
                </Modal.Body>
            </Modal>
        </div>
    );
}