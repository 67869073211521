// CircularIndeterminate.js
import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

const styles = {
  loaderContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(255, 255, 255, 0.5)', // semi-transparent background to overlay on content
    zIndex: 9999, // higher z-index to ensure it's on top of content
  },
};

const CircularIndeterminate = () => {
  return (
    <Box sx={styles.loaderContainer}>
      <CircularProgress />
    </Box>
  );
};

export default CircularIndeterminate;
