import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
//importing custom pages created
import ParentGuardianSignUp from './Components/UserManagement/ParentGuardianSignUp';
import SignIn from './Components/UserManagement/SignIn';
import AddNewQuestions from './Components/QBAdmin/AddNewQuestions';
import PracticeTest from './Components/TestEngine/PracticeTest';
import Header from './Components/Static/Header';
import ResetPassword from './Components/UserManagement/ResetPassword';
import RetrievePassword from './Components/UserManagement/RetrievePassword';
import ProtectedRoute from './Components/Static/ProtectedRoute';
import PPStartPage from './Components/TestEngine/PPStartPage';
import { ActivationRedirect } from './Components/UserManagement/Activation';
import Home from './Components/UserManagement/Home';
import TestResults from './Components/TestEngine/TestResults';
import ModifyQuestions from './Components/QBAdmin/ModifyQuestions';
import { ResetPasswordRedirect } from './Components/UserManagement/RequestResetPassword';
import GeneralMessage from './Components/UserManagement/GeneralMessage';
import QBDashboard from './Components/QBAdmin/QBDashboard';
import DuplicateValues from './Components/QBAdmin/DuplicateValues';

import { LoadingProvider, useLoading  } from './Components/Static/LoadingContext';
import CircularIndeterminate from './Components/Static/CircularIndeterminate';
import MathsQuestions from './Components/QBAdmin/MathsQuestions';
import PracticeTestsList from './Components/TestEngine/PracticeTestsList';
import Forbidden from './Components/Forbidden';

function App() {
  return (
    <div className="App bg-light">
      <LoadingProvider>
      <Router>
        <Header />
        <Loading />
        <Routes>
          <Route path="/" element={<SignIn />} />
          <Route path="/parentguardiansignup" element={<ParentGuardianSignUp />} />
          <Route path="/resetpassword" element={<ResetPassword />} />
          <Route path="/forgotpassword" element={<RetrievePassword />} />
          <Route path="/activate/:id/" element={<ActivationRedirect />} />
          <Route path="/reset-password/:id/" element={<ResetPasswordRedirect />} />
          <Route path="/generalmessage" element={<GeneralMessage />} />
          <Route element={<ProtectedRoute allowedRoles={['child', 'super admin', 'qbAdmin', 'parent']}/>}>
            <Route path="/home" element={<Home />} />
          </Route>
          
          <Route element={<ProtectedRoute allowedRoles={['child', 'super admin', 'qbAdmin']}/>}>
            <Route path="/practicetest" element={<PracticeTest />} />
            <Route path="/ppstartpage" element={<PPStartPage />} />
            <Route path="/testresults" element={<TestResults />} />
          </Route>
          
          <Route element={<ProtectedRoute allowedRoles={['qbAdmin', 'super admin', 'qbDeveloper']}/>}>
            {/* <Route path="/addquestions" element={<AddNewQuestions />} /> */}
            <Route path="/modifyquestions" element={<ModifyQuestions />} />
            <Route path="/mathsquestions" element={<MathsQuestions />} />
          </Route>
          
          <Route element={<ProtectedRoute allowedRoles={['qbAdmin', 'super admin']} />}>
              <Route path="/addquestions" element={<AddNewQuestions />} />
              <Route path="/practiceTestsList" element={<PracticeTestsList />} />
              <Route path="/QBDashboard" element={<QBDashboard />} />
              <Route path="/DuplicateValues" element={<DuplicateValues />} />
            </Route>
          
          <Route path="/noaccess" element={<Forbidden />} />
        </Routes>
        <div>
        </div>
      </Router>
      </LoadingProvider>

    </div>
  );
}

const Loading = () => {
  const { isLoading } = useLoading();
  return isLoading ? <CircularIndeterminate /> : null;  // Show loader if isLoading is true
};

export default App;