import React, { useState, useEffect } from 'react';
import BootstrapeTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import cellEditFactory, { Type } from "react-bootstrap-table2-editor";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import {
  Row,
  Form
} from 'react-bootstrap';
import axios from 'axios';
import { config } from 'mathjs';



export default function ModifyQuestions() {

  const [MQInput, setMQInput] = useState({
    subject: "",
    choice: "",
  });
  const [QBData, setQBData] = useState([]);
  const [sameDatas, setSameData] = useState([]);
  const [data, setData] = useState([]);
  const [stackedBarGraph, setStackedBarGraph] = useState([]);
  const [fibData, setFibData] = useState([]);
  const [mcqData, setMcqData] = useState([]);
  

  useEffect(() => {
    if (MQInput.subject) {
      ChangeSubject(MQInput.subject);
    }
  }, [MQInput.subject]);

  const handleSubjectChange = (e) => {
    setMQInput(prevState => ({
      ...prevState,
      subject: e.target.value
    }));
  }

  const ChangeSubject = (subject) => {
    const token = localStorage.getItem("authToken")
    const config = {
      headers: { Authorization: `Token ${token}` }
    };

    console.log("MQInput", subject)

    let url = "";

    switch (subject) {
      case "Maths":
        url = process.env.REACT_APP_BACKEND_API_URL + "/get-maths-data/";
        break;
      case "English":
        url = process.env.REACT_APP_BACKEND_API_URL + "/get-english-data/";
        break;
      case "VR":
        url = process.env.REACT_APP_BACKEND_API_URL + "/get-vr-data/";
        break;
      case "NVR":
        url = process.env.REACT_APP_BACKEND_API_URL + "/get-nvr-data/";
        break;
      default:
        return;
    }

    axios.get(url, config)
          .then((response) => {
            const data = response.data;
            const fibQuestions = data.filter(item => item.questionType === 'FIB');
            const mcqQuestions = data.filter(item => item.questionType === 'MCQ');
            setQBData(data);
            setFibData(fibQuestions);
            setMcqData(mcqQuestions);
            

          });
    
  };


  const handleCellEdit = (oldValue, newValue, row, column) => {
    const updatedData = [...QBData];
    // console.log(updatedData);
    const index = updatedData.findIndex(item => (item.id === row.id && item.questionType === row.questionType));
    updatedData[index][column.dataField] = newValue;
    setData(updatedData);
    const token = localStorage.getItem("authToken")
    const config = {
      headers: { Authorization: `Token ${token}` }
    };

    const subject = MQInput.subject
    console.log(MQInput.subject, "Inside handlecelledit")
    let url = "";

    if(subject === "Maths"){
      if (row.questionType == "FIB"){
        url = process.env.REACT_APP_BACKEND_API_URL + "/MathsFIBUpdate/" + `${row.id}` + "/"
      }
      else if (row.questionType === "MCQ"){
        url = process.env.REACT_APP_BACKEND_API_URL + "/MathsMCUpdate/" + `${row.id}` + "/"
      }
    }
    else if(subject === "English"){
      if (row.questionType == "FIB"){
        url = process.env.REACT_APP_BACKEND_API_URL + "/EnglishFIBUpdate/" + `${row.id}` + "/"
      }
      else if (row.questionType === "MCQ"){
        url = process.env.REACT_APP_BACKEND_API_URL + "/EnglishMCUpdate/" + `${row.id}` + "/"
      }
    }
    else if(subject === "VR"){
      if (row.questionType == "FIB"){
        url = process.env.REACT_APP_BACKEND_API_URL + "/VRFIBUpdate/" + `${row.id}` + "/"
      }
      else if (row.questionType === "MCQ"){
        url = process.env.REACT_APP_BACKEND_API_URL + "/VRMCUpdate/" + `${row.id}` + "/"
      }
    }
    else if(subject === "NVR"){
      if (row.questionType == "FIB"){
        url = process.env.REACT_APP_BACKEND_API_URL + "/NVRFIBUpdate/" + `${row.id}` + "/"
      }
      else if (row.questionType === "MCQ"){
        url = process.env.REACT_APP_BACKEND_API_URL + "/NVRMCUpdate/" + `${row.id}` + "/"
      }
    }

    axios.put(url, row, config)
      .then(response => {
        setQBData(prevData => {
          const updatedData = [...prevData];
          const index = updatedData.findIndex(item => (item.id === row.id && item.questionType === row.questionType));
          updatedData[index][column.dataField] = newValue;
          return updatedData;
        });
      })
      .catch(error => {
        // console.log(error);
      });
  }

  const uniqueDifficultyLevels = new Set();
  stackedBarGraph.forEach((graph) => {
    graph.difficulties.forEach((difficulty) => {
      uniqueDifficultyLevels.add(difficulty.difficultyLevel);
    });
  });



  const columns1 = [
    
    {
      dataField: "id",
      text: "ID",
      sort: true,
      editable: false,
      filter: textFilter(),
    },
    {
      dataField: "baseQuestionID",
      text: "Base QuestionID",
      sort: false,
      editable: true,
      filter: textFilter(),
    },
    {
      dataField: "questionDescription",
      text: "Question Description",
      sort: false,
      editable: true,
      filter: textFilter(),
    },
    {
      dataField: "primary_topic",
      text: "Primary Topic",
      sort: true,
      editable: false,
      filter: textFilter(),
      formatter: (cell, row) => {
        return row.primary_topic.join(",");
      }
    },
    {
      dataField: "answerPrefix",
      text: "Answer Prefix",
      sort: false,
      editable: true,
      filter: textFilter(),
    },
    {
      dataField: "answerSuffix",
      text: "Answer Suffix",
      sort: false,
      editable: true,
      filter: textFilter(),
    },
    {
      dataField: "correctAnswer",
      text: "Correct Answer",
      sort: false,
      editable: true,
      filter: textFilter(),
    },
    {
      dataField: "answerExplanation",
      text: "Answer Explaination",
      sort: false,
      editable: true,
      filter: textFilter(),
    },
    {
      dataField: "questionType",
      text: "Type",
      sort: true,
      editable: false
  }

  ];
  const columns2 = [
    {
      dataField: "id",
      text: "ID",
      sort: true,
      editable: false,
      filter: textFilter(),
    },
    {
      dataField: "baseQuestionID",
      text: "Base QuestionID",
      sort: false,
      editable: true,
      filter: textFilter(),
    },
    {
      dataField: "questionDescription",
      text: "Question Description",
      sort: false,
      editable: true,
      filter: textFilter(),
    },
    {
      dataField: "ansOption1",
      text: "Option 1",
      sort: false,
      editable: true,
      filter: textFilter(),
    },
    {
      dataField: "ansOption2",
      text: "option 2",
      sort: false,
      editable: true,
      filter: textFilter(),
    },
    {
      dataField: "ansOption3",
      text: "Option 3",
      sort: false,
      editable: true,
      filter: textFilter(),
    },
    {
      dataField: "ansOption4",
      text: "Option 4",
      sort: false,
      editable: true,
      filter: textFilter(),
    },
    {
      dataField: "ansOption5",
      text: "Option 5",
      sort: false,
      editable: true,
      filter: textFilter(),
    },
    {
      dataField: "ansOption6",
      text: "Option 6",
      sort: false,
      editable: true,
      filter: textFilter(),
    },
    {
      dataField: "primary_topic",
      text: "Primary Topic",
      sort: true,
      editable: false,
      filter: textFilter(),
      formatter: (cell, row) => {
        return row.primary_topic.join(",");
      }
    },
    {
      dataField: "correctAnswer",
      text: "Correct Answer",
      sort: false,
      editable: true,
      filter: textFilter(),
    },
    {
      dataField: "answerExplanation",
      text: "Answer Explaination",
      sort: false,
      editable: true,
      filter: textFilter(),
    },
    {
      dataField: "questionType",
      text: "Type",
      sort: true,
      editable: false
  }
  ];
  const columns3 = [
    {
      dataField: "baseQuestionID",
      text: "Base QuestionID",
      sort: false,
      editable: true,
      filter: textFilter(),
    },
    {
      dataField: "questionDescription",
      text: "Question Description",
      sort: false,
      editable: true,
      // filter: textFilter(),
    },
    {
      dataField: "academicYear",
      text: "Academic Year",
      sort: true,
      editable: true,
      filter: textFilter(),
    },
  ];
  const columns4 = [

    {
      dataField: "questionDescription",
      text: "Question Description",
      sort: false,
      editable: true,
      filter: textFilter(),
    },

    {
      dataField: "correctAnswer",
      text: "Correct Answer",
      sort: true,
      editable: false,

    },
    {
      dataField: "ansOption1",
      text: "ansOption1",
      sort: true,
      editable: false,

    },
    {
      dataField: "ansOption2",
      text: "ansOption2",
      sort: true,
      editable: false,

    },
    {
      dataField: "ansOption3",
      text: "ansOption3",
      sort: true,
      editable: false,
    },
    {
      dataField: "ansOption4",
      text: "ansOption4",
      sort: true,
      editable: false,
    },
    {
      dataField: "ansOption5",
      text: "ansOption5",
      sort: true,
      editable: false,
    },
    {
      dataField: "ansOption6",
      text: "ansOption6",
      sort: true,
      editable: false,
    },
    {
      dataField: "primary_topic",
      text: "Primary Topic",
      sort: true,
      editable: true,
      filter: textFilter(),
      formatter: (cell, row) => {
        return row.primary_topic.join(",");
      }
    },
    {
      dataField: "difficultyLevel",
      text: "Difficulty Level",
      sort: true,
      editable: true,
      filter: textFilter(),
    },

    ,
  ];

  

  const handleChoiceChange = (e) => {
    const { name, value } = e.target;
    setMQInput((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  return (
    
    <div>
      <h3>Modify Questions here</h3>
      <Row>
        <Form.Group controlId="subject">
          <Form.Select
            style={{ fontSize: "14px" }}
            onChange={(e) => handleSubjectChange(e)}
            name="subject"
          >
            <option value="">Select Subject</option>
            <option value="Maths">Maths</option>
            <option value="English">English</option>
            <option value="VR">VR</option>
            <option value="NVR">NVR</option>
          </Form.Select>
        </Form.Group>
        <Form.Group controlId="choice">
          <Form.Select
            style={{ fontSize: "14px" }}
            onChange={(e) => handleChoiceChange(e)}
            name="choice"
          >
            <option value="">Filter Questions</option>
            <option value="table1">FIB Questions</option>
            <option value="table2">MCQ Questions</option>
            {/* <option value="table3">Questions by Academic Year</option>
            <option value="table4">Questions by Answers with stacked bar graph</option> */}
          </Form.Select>
        </Form.Group>
      </Row>
      {sameDatas.questionDescription && <BootstrapeTable keyField="id" data={sameDatas} columns={columns4} />}


      <Row style={{ margin: "10px" }}>

        <div className="mt-3">
          {MQInput.choice === "table1" && (
            <BootstrapeTable
              keyField="id"
              data={fibData}
              columns={columns1}
              striped
              hover
              condensed
              pagination={paginationFactory()}
              cellEdit={cellEditFactory({
                mode: 'click',
                blurToSave: true,
                nonEditableRows: () => [0],
                beforeSaveCell: (oldValue, newValue, row, column, done) => {
                  if (oldValue !== newValue) {
                    handleCellEdit(oldValue, newValue, row, column);
                  }
                  done();
                }
              })}
              filter={filterFactory()}
            />)
          }
          {MQInput.choice === "table2" && (
            <BootstrapeTable
              keyField="id"
              data={mcqData}
              columns={columns2}
              striped
              hover
              condensed
              pagination={paginationFactory()}
              cellEdit={cellEditFactory({
                mode: 'click',
                blurToSave: true,
                nonEditableRows: () => [0],
                beforeSaveCell: (oldValue, newValue, row, column, done) => {
                  if (oldValue !== newValue) {
                    handleCellEdit(oldValue, newValue, row, column);
                  }
                  done();
                }
              })}
              filter={filterFactory()}
            />
          )
          }
          {MQInput.choice === "table3" && (
            <BootstrapeTable
              keyField="id"
              data={QBData}
              columns={columns3}
              striped
              hover
              condensed
              pagination={paginationFactory()}
              cellEdit={cellEditFactory({
                mode: 'click',
                blurToSave: true,
                nonEditableRows: () => [0],
                beforeSaveCell: (oldValue, newValue, row, column) => {
                  handleCellEdit(oldValue, newValue, row, column);
                }
              })}
              // selectRow = {selectRow}
              filter={filterFactory()}
            />)
          }
          {MQInput.choice === "table4" && (
            <BootstrapeTable
              keyField="id"
              data={QBData}
              columns={columns4}
              striped
              hover
              condensed
              pagination={paginationFactory()}
              cellEdit={cellEditFactory({
                mode: 'click',
                blurToSave: true,
                nonEditableRows: () => [0],
                beforeSaveCell: (oldValue, newValue, row, column) => {
                  handleCellEdit(oldValue, newValue, row, column);
                }
              })}

              filter={filterFactory()}
            />)
          }
        </div>
      </Row>

    </div>
  )
}
