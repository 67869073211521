import React from "react";
import { Row, Col, Form, Button } from "react-bootstrap";
import Metadata from "./MetaData";

const FIBForm =(props)=>{
    return(
        <>
         <Row>
                <Col>
                    <h5 className="mt-4">Add fill in blank questions</h5>
                    <Form>
                        <textarea
                            className="mt-2"
                            type="text"
                            rows={4}
                            cols={60}
                            name="questionDescription"
                            placeholder="Question Description"
                            value={props.propvalues.questionDescription}
                            onChange={props. handleChanges}
                        ></textarea>
                    </Form>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form>
                        <label className="mb-1 mt-2">Upload image if available </label>
                        <input
                            className="form-control"
                            id="image"
                            type="file"
                            name="image"
                            value={""}
                            onChange={""}
                        />
                    </Form>
                </Col>
            </Row>
            <Row>
                <Col>
                    <textarea
                        type="textarea"
                        rows={5}
                        cols={60}
                        name="answer"
                        placeholder="Correct Anwser"
                        value={props.propvalues.correctAnswer}
                        onChange={props.handleChanges}
                        className='mt-4'
                    ></textarea>

                </Col>
            </Row>
            <Row>
                <Col>
                    <textarea
                        type="textarea"
                        rows={5}
                        cols={60}
                        name="answerExplanations"
                        placeholder="Anwser Explanation"
                        value={props.propvalues.answerExplanation}
                        onChange={props.handleChanges}
                        className='mt-4'
                    ></textarea>
                </Col>
            </Row>
        </>
    )
}
export default FIBForm;