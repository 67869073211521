import React, { useState, useEffect } from "react";
import { Alert } from "react-bootstrap";

const SuccessMessage = ({ message }) => {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setIsVisible(false);
    }, 4000); 
    return () => clearTimeout(timeoutId);
  }, []);

  return (
    <>
      {isVisible && (
        <Alert key="success" variant="success">
          {message}
        </Alert>
      )}
    </>
  );
};

export default SuccessMessage;
