import React, { useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Form
} from "react-bootstrap";
import FibQuestions from "./FibQuestions";
import MCQuestions from "./MCQuestions";
import Mpq from "./Mpq";

const AddNewQuestions = () => {
  const [selectedSubject, setSelectedSubject] = useState(null);
  const [selectedType, setSelectedType] = useState(null);
  return (
    <>
      <Container
        fluid
        style={{ minHeight: "100vh", display: "flex", flexDirection: "column" }}
      >
        <Row>
          <Col md="2"></Col>
          <Col md="8" className="mt-4">
            <Card
              className="mt-1 mb-4"
              style={{
                maxWidth: "800px",
                margin: "0 auto",
                alignItems: "center",
              }}
            >
              <Card.Body>
                <h3 className="fw-bold mb-md-3">Add new questions here</h3>
                <Row>
                  <Col md="6">
                    <Form.Select
                      value={selectedSubject}
                      onChange={(e) => setSelectedSubject(e.target.value)}
                    >
                      <option value="DEFAULT">Select Subject</option>
                      <option value="maths">Maths</option>
                      <option value="english">English</option>
                      <option value="vr">Verbal Reasoning</option>
                      <option value="nvr">Non Verbal Reasoning</option>
                    </Form.Select>
                  </Col>

                  <Col md="6">
                    {selectedSubject === "maths" ||
                      selectedSubject === "english" ||
                      selectedSubject === "vr" ||
                      selectedSubject === "nvr" ? (
                      <Form.Select
                        value={selectedType}
                        onChange={(e) => setSelectedType(e.target.value)}
                      >
                        <option value="DEFAULT">Select Question Type</option>
                        <option value="fillintheblanks">
                          Fill In The Blanks
                        </option>
                        <option value="multiplechoice">Multiple Choice</option>
                        <option value="multipart">Multi Part</option>
                      </Form.Select>
                    ) : (
                      <Form.Select
                        value={selectedType}
                        onChange={(e) => setSelectedType(e.target.value)}
                      >
                        <option value="DEFAULT">Select Question Type</option>
                      </Form.Select>
                    )}
                  </Col>

                </Row>

                {(selectedSubject === "maths" ||
                  selectedSubject === "english" ||
                  selectedSubject === "vr" ||
                  selectedSubject === "nvr") &&
                  selectedType === "fillintheblanks" && (
                    <FibQuestions
                      subject={selectedSubject}
                      key={selectedType}
                    />
                  )}
                {(selectedSubject === "maths" ||
                  selectedSubject === "english" ||
                  selectedSubject === "vr" ||
                  selectedSubject === "nvr") &&
                  selectedType === "multiplechoice" && (
                    <MCQuestions
                      subject={selectedSubject}
                      key={selectedType}
                    />
                  )}
                {(selectedSubject === "maths" ||
                  selectedSubject === "english" ||
                  selectedSubject === "vr" ||
                  selectedSubject === "nvr") &&
                  selectedType === "multipart" && (
                    <Mpq
                      subject={selectedSubject}
                      key={selectedType}
                    />
                  )}
              </Card.Body>
            </Card>
          </Col>
          <Col md="2"></Col>
        </Row>
      </Container>
    </>
  );
};

export default AddNewQuestions;
