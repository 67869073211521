
import React, { useState, useEffect } from "react";
import "../CssFiles/PracticeTest.css";

// function TimerPage(props) {
//   const [timeLeft, setTimeLeft] = useState(() => {
//     const storedTimeLeft = localStorage.getItem("timeLeft");
//     return storedTimeLeft ? parseInt(storedTimeLeft, 10) : props.time * 60;
//   });
//   const [isRunning, setIsRunning] = useState(true);

//   useEffect(() => {
//     localStorage.setItem("timeLeft", timeLeft.toString());
//   }, [timeLeft]);

//   useEffect(() => {
//     if (isRunning && timeLeft > 0) {
//       const timerId = setTimeout(() => setTimeLeft(timeLeft - 1), 1000);
//       return () => clearTimeout(timerId);
//     } else if (timeLeft === 0) {
//       setIsRunning(false);
//       props.submit();
//     }
//   }, [timeLeft, isRunning, props]);

//   const hours = Math.floor(timeLeft / 3600);
//   const minutes = Math.floor((timeLeft % 3600) / 60);
//   const seconds = timeLeft % 60;

//   return (
//     <>
//       {hours.toString().padStart(2, "0")}:
//       {minutes.toString().padStart(2, "0")}:
//       {seconds.toString().padStart(2, "0")}
//     </>
//   );
// }

export default TimerPage;
function TimerPage({ timeLeft, isRunning }) {
  // Timer display logic
  const hours = Math.floor(timeLeft / 3600);
  const minutes = Math.floor((timeLeft % 3600) / 60);
  const seconds = timeLeft % 60;
  
  return (
  <>
  {hours.toString().padStart(2, "0")}:
  {minutes.toString().padStart(2, "0")}:
  {seconds.toString().padStart(2, "0")}
  </>
  );
  }
  
  // export default TimerPage;

