import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";
import axios from "axios";
import {
    Container,
    Navbar,
    Nav,
    NavDropdown,
    Row,
    Col,
    Card,
    Form,
    Button,
    Dropdown,
} from "react-bootstrap";

import { create, all } from 'mathjs';
import { CKEditor } from '@ckeditor/ckeditor5-react';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import ClassicEditor from 'ckeditor5-build-classic-mathtype'


const FibQuestions = (props) => {
    // const editorConfiguration = {
    //     plugins: [MathType],
    //     toolbar: ['MathType'],
    //     // other configuration options
    // };


    const [fibMetadata, setFibMetadata] = useState({
        dataSource: "",
        sourceYear: "",
        difficultyLevel: "",
        academicYear: "",
    });
    const [topics, setTopics] = useState([]);



    const commonValue = "______________________";
    const subject = props.subject;
    const [firstHalf, setFirstHalf] = useState("");
    const [secondHalf, setSecondHalf] = useState("");
    const [answer, setAnswer] = useState("");
    const [answerExplanations, setAnswerExplanations] = useState("");
    const [result, setResult] = useState("Output will Appear Here");
    const [editorContent, setEditorContent] = useState('');

    const onInputChange = (e) => {
        const { name, value } = e.target;
        setFibMetadata((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    };
    const handleTopicChange = (event) => {
        const topic = event.target.value;
        const isChecked = event.target.checked;

        if (isChecked) {
            setTopics([...topics, topic]);
        } else {
            setTopics(topics.filter((t) => t !== topic));
        }
    };

    const handleFirstChange = (event) => {

        setFirstHalf(event.target.value);
    };

    const handleSecondChange = (event) => {
        setSecondHalf(event.target.value);
    };

    const handleAnswer = (event) => {
        setAnswer(event.target.value);
    };
    const handleAnswerExplanation = (event) => {
        setAnswerExplanations(event.target.value);
    };


    const Save = (e) => {
        e.preventDefault();

        const formData = new FormData();

        formData.append('questionDescription', firstHalf + commonValue + secondHalf);
        formData.append('correctAnswer', answer);
        formData.append('answerExplanation', answerExplanations);
        formData.append('difficultyLevel', fibMetadata.difficultyLevel);
        formData.append('academicYear', fibMetadata.academicYear);
        formData.append('dataSource', fibMetadata.dataSource);
        formData.append('sourceYear', fibMetadata.sourceYear);
        // const topicsArray = topics.map((t) => t.trim());
        formData.append('topic', topics);
        {
            subject === "maths" &&
                axios
                    .post(process.env.REACT_APP_BACKEND_API_URL + "/MathsFIB/create/",

                        formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        },
                    })
                    .then((response) => {
                        // console.log(response);
                    })
                    .catch((error) => {
                        // console.log(error);
                    });
        }
        {
            subject === "english" &&
                axios
                    .post(process.env.REACT_APP_BACKEND_API_URL + "/EnglishFIB/create/",

                        formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        },
                    })
                    .then((response) => {
                        // console.log(response);
                    })
                    .catch((error) => {
                        // console.log(error);
                    });

        }
    };
    const SaveNew = (e) => {
        const fibQuestion = firstHalf + commonValue + secondHalf;
        // console.log(fibQuestion);
        e.preventDefault();
        {
            subject === "maths" &&
                axios
                    .post(process.env.REACT_APP_BACKEND_API_URL + "/fillintheblanks/create/", {
                        fibQuestion,
                        answer,
                    })
                    .then((response) => {
                        setResult(
                            response.data.fibQuestion +
                            ": Answer for this is" +
                            " : " +
                            response.data.answer
                        );
                    })
                    .catch((error) => {
                        // console.log(error);
                    });
            setFirstHalf("");
            setSecondHalf("");

        }
    };
    const SaveClose = (e) => {
        const fibQuestion = firstHalf + commonValue + secondHalf;
        // console.log(fibQuestion);
        e.preventDefault();
        {
            subject === "maths" &&
                axios
                    .post(process.env.REACT_APP_BACKEND_API_URL + "/fillintheblanks/create/", {
                        fibQuestion,
                        answer,
                    })
                    .then((response) => {
                        setResult(
                            response.data.fibQuestion +
                            ": Answer for this is" +
                            " : " +
                            response.data.answer
                        );
                    })
                    .catch((error) => {
                        // console.log(error);
                    });
        }
    };

    return (
        <>
            <Row>
                <Col>
                    <h5 className="mt-4">Add fill in blank questions</h5>
                    <CKEditor
                            editor={ClassicEditor}
                            
                            config={{
                                plugins: [ 'MathType' ],
                                toolbar: {
                                    items: [
                                        'heading', 'MathType', 'ChemType',
                                        '|',
                                        'bold',
                                        'italic',
                                        'link',
                                        'bulletedList',
                                        'numberedList',
                                        'imageUpload',
                                        'mediaEmbed',
                                        'insertTable',
                                        'blockQuote',
                                        'undo',
                                        'redo'
                                    ]
                                },
                                mathType: {
                                    mathmlXmlEncoding: 'raw',
                                    mathmlAttributeEncoding: 'xml',
                                    generic: false,
                                    enhancedEditing: true,
                                    defaultMode: 'display',
                                    toolbar: 'full',
                                    autoSubscriptNumerals: true,
                                    autoSubscriptGreekLetters: true,
                                    autoCommands: 'pi theta sqrt sum',
                                    maxBuffer: 100000,
                                    minSymbols: 10,
                                    maxLength: 2000
                                }                                
                            }}
                            
                            data={editorContent}
                            onChange={(event, editor) => {
                                const data = editor.getData();
                                setEditorContent(data);
                            }}
                            onInit={editor => {
                                // You can store the "editor" and use when it is needed.
                                // // console.log( 'Editor is ready to use!', editor );
                            }}
                        />
                    
                    <Form>



                        {/* <textarea
                                    className="mt-2"
                                    type="textarea"
                                    rows={5}
                                    cols={60}
                                    name="firsthalf"
                                    placeholder="First Half"
                                    value={firstHalf}
                                    onChange={handleFirstChange}
                                /> */}


                        <div style={{ textAlign: "center" }}>{commonValue}</div>
                        <textarea
                            className="mt-2"
                            type="textarea"
                            rows={5}
                            cols={60}
                            name="secondhalf"
                            placeholder="Second Half"
                            value={secondHalf}
                            onChange={handleSecondChange}
                        ></textarea>
                    </Form>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form>
                        <label className="mb-1 mt-2">Upload image if available </label>
                        <input
                            className="form-control"
                            id="image"
                            type="file"
                            name="image"
                            value={""}
                            onChange={(e) => onInputChange(e)}
                        />
                    </Form>
                </Col>
            </Row>
            <Row>
                <Col>
                    <textarea
                        type="textarea"
                        rows={5}
                        cols={60}
                        name="answer"
                        placeholder="Correct Anwser"
                        value={answer}
                        onChange={handleAnswer}
                        className='mt-4'
                    ></textarea>

                </Col>
            </Row>
            <Row>
                <Col>
                    <textarea
                        type="textarea"
                        rows={5}
                        cols={60}
                        name="answerExplanations"
                        placeholder="Anwser Explanation"
                        value={answerExplanations}
                        onChange={handleAnswerExplanation}
                        className='mt-4'
                    ></textarea>
                </Col>
            </Row>
            <Row>
                <hr className="mt-3"></hr>
                <Col>
                    <Form.Select
                        style={{ fontSize: "14px" }}
                        onChange={(e) => onInputChange(e)}
                        id="dataSource"
                        name="dataSource"
                        className="mt-2"
                    >
                        <option value="">Select Data Source</option>
                        <option value="Seven Oaks">Seven Oaks</option>
                        <option value="MGS">MGS</option>
                        <option value="LEAP">LEAP</option>
                        <option value="AE">AE</option>
                        <option value="SDE">SDE</option>
                        <option value="Chuckra">Chuckra</option>
                        <option value="CGP">CGP</option>
                        <option value="ourOwnManual">Original Manual</option>
                        <option value="ourOwnAutogen">
                            Original Auto Generated
                        </option>
                    </Form.Select>


                </Col>
                <Col>
                    <Form.Select
                        style={{ fontSize: "14px" }}
                        onChange={(e) => onInputChange(e)}
                        id="sourceYear"
                        name="sourceYear"
                        className="mt-2"
                    >
                        <option value="">Select Source Year</option>
                        <option value="NA">NA</option>
                        <option value="2010">2010</option>
                        <option value="2011">2011</option>
                        <option value="2012">2012</option>
                        <option value="2013">2013</option>
                        <option value="2014">2014</option>
                        <option value="2015">2015</option>
                        <option value="2016">2016</option>
                        <option value="2017">2017</option>
                        <option value="2018">2018</option>
                        <option value="2019">2019</option>
                        <option value="2020">2020</option>
                        <option value="2021">2021</option>
                        <option value="2022">2022</option>
                        <option value="2023">2023</option>
                    </Form.Select>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form.Select
                        style={{ fontSize: "14px" }}
                        onChange={(e) => onInputChange(e)}
                        id="difficultyLevel"
                        name="difficultyLevel"
                        className="mt-4"
                    >
                        <option value="">Select Difficulty Level</option>
                        <option value="Level 1">Level 1</option>
                        <option value="Level 2">Level 2</option>
                        <option value="Level 3">Level 3</option>
                        <option value="Level 4">Level 4</option>
                        <option value="Level 5">Level 5</option>
                        <option value="Level 6">Level 6</option>
                        <option value="Level 7">Level 7</option>
                        <option value="Level 8">Level 8</option>
                    </Form.Select>

                </Col>
                <Col>
                    <Form.Select
                        style={{ fontSize: "14px" }}
                        onChange={(e) => onInputChange(e)}
                        id="academicYear"
                        name="academicYear"
                        className="mt-4"
                    >
                        <option value="">Select Academic Year</option>
                        <option value="Year 3">Year 3</option>
                        <option value="Year 4">Year 4</option>
                        <option value="Year 5">Year 5</option>
                        <option value="Year 6">Year 6</option>
                        <option value="Year 7">Year 7</option>
                        <option value="Year 8">Year 8</option>
                        <option value="Year 9">Year 9</option>
                        <option value="Year 10">Year 10</option>
                    </Form.Select>

                </Col>
            </Row>
            {(props.subject == "maths") &&
                <Row>
                    <hr className="mt-3"></hr>
                    <label className="mb-2 mt-2">Select applicable topics </label>

                    <Col>


                        <Form.Check
                            type="switch"
                            id="Fractions"
                            name="Fractions"
                            label="Fractions"
                            value="Fractions"
                            checked={topics.includes("Fractions")}
                            onChange={handleTopicChange}
                        />
                        <Form.Check
                            type="switch"
                            id="Numbers, Place Value"
                            name="Numbers, Place Value"
                            label="Numbers, Place Value"
                            value="Numbers, Place Value"
                            checked={topics.includes("Numbers, Place Value")}
                            onChange={handleTopicChange}
                        />
                        <Form.Check
                            type="switch"
                            id="Geometric Properties"
                            name="Geometric Properties"
                            label="Geometric Properties"
                            value="Geometric Properties"
                            checked={topics.includes("Geometric Properties")}
                            onChange={handleTopicChange}
                        />
                        <Form.Check
                            type="switch"
                            id="Geometric Shapes"
                            name="Geometric Shapes"
                            label="Geometric Shapes"
                            value="Geometric Shapes"
                            checked={topics.includes("Geometric Shapes")}
                            onChange={handleTopicChange}
                        />

                    </Col>
                    <Col>
                        <Form.Check
                            type="switch"
                            id="Measurement"
                            name="Measurement"
                            label="Measurement"
                            value="Measurement"
                            checked={topics.includes("Measurement")}
                            onChange={handleTopicChange}
                        />
                        <Form.Check
                            type="switch"
                            id="Percentages"
                            name="Percentages"
                            label="Percentages"
                            value="Percentages"
                            checked={topics.includes("Percentages")}
                            onChange={handleTopicChange}
                        />
                        <Form.Check
                            type="switch"
                            id="Decimals"
                            name="Decimals"
                            label="Decimals"
                            value="Decimals"
                            checked={topics.includes("Decimals")}
                            onChange={handleTopicChange}
                        />
                        <Form.Check
                            type="switch"
                            id="Arithmetic"
                            name="Arithmetic"
                            label="Arithemtic"
                            value="Arithmetic"
                            checked={topics.includes("Arithmetic")}
                            onChange={handleTopicChange}
                        />
                    </Col>
                </Row>
            }
            {(props.subject == "english") &&
                <Row>
                    <Col>
                        <Form.Check
                            type="switch"
                            id="Antonym"
                            name="Antonym"
                            label="Antonym"
                            value="Antonym"
                            checked={topics.includes("Antonym")}
                            onChange={handleTopicChange}
                        />
                        <Form.Check
                            type="switch"
                            id="Synonym"
                            name="Synonym"
                            label="Synonym"
                            value="Synonym"
                            checked={topics.includes("Synonym")}
                            onChange={handleTopicChange}
                        />
                        <Form.Check
                            type="switch"
                            id="Close"
                            name="Close"
                            label="Close"
                            value="Close"
                            checked={topics.includes("Close")}
                            onChange={handleTopicChange}
                        />
                    </Col>
                </Row>
            }
            <Row className="mt-3">
                <Col>
                    <Button
                        className="fw-bold ripple ripple-surface btn btn-primary btn-md w-100 mb-4"
                        role="button"
                        onClick={SaveNew}
                    >Save & New
                    </Button>
                </Col>
                <Col>
                    <Button
                        className="fw-bold ripple ripple-surface btn btn-primary btn-md w-100 mb-4"
                        role="button"
                        onClick={Save}
                    >Save
                    </Button>
                </Col>
                <Col>
                    <Button
                        className="fw-bold ripple ripple-surface btn btn-primary btn-md w-100 mb-4"
                        role="button"
                        onClick={SaveClose}
                    > Save & Close
                    </Button>
                </Col>
            </Row>

        </>
    );
};

export default FibQuestions;
