import React, { useState, useEffect } from 'react';
import BootstrapeTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import cellEditFactory, { Type } from "react-bootstrap-table2-editor";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import {
    Row,
    Form
} from 'react-bootstrap';
import axios from 'axios';



export default function DuplicateValues() {

    const [MQInput, setMQInput] = useState({
        subject: "",
        choice: "",
    });
    const [sameDatas, setSameData] = useState([]);

    const token = localStorage.getItem("authToken")
    const config = {
        headers: { Authorization: `Token ${token}` }
    };

    useEffect(() => {
        if (MQInput.subject === "Maths") {
            axios.get((process.env.REACT_APP_BACKEND_API_URL) + "/get-maths-data/", "", config)
                .then((response) => {
                    // console.log(response.data)
                    const sameData = response.data.reduce((acc, curr) => {
                        const questionDesc = curr.questionDescription;
                        if (!acc[questionDesc]) {
                            acc[questionDesc] = [];
                        }
                        acc[questionDesc].push(curr);
                        return acc;
                    }, {});
                    setSameData(sameData)

                });
        }
    }, [MQInput.subject]);

    const columns1 = [
        {
            dataField: "baseQuestionID",
            text: "Base QuestionID",
            sort: false,
            editable: true,
            filter: textFilter(),
        },
        {
            dataField: "questionDescription",
            text: "Question Description",
            sort: false,
            editable: true,
            filter: textFilter(),
        },
        {
            dataField: "topic",
            text: "Topic",
            sort: true,
            editable: true,
            filter: textFilter(),
            formatter: (cell, row) => {
                return row.topic.join(",");
            }
        },

    ];

    const onInputChange = (e) => {
        const { name, value } = e.target;
        setMQInput((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    };

    return (

        <div>
            <h3>Check Duplicate Values here</h3>
            <Row>
                <Form.Group controlId="subject">
                    <Form.Select
                        style={{ fontSize: "14px" }}
                        onChange={(e) => onInputChange(e)}
                        name="subject"
                    >
                        <option value="">Select Subject</option>
                        <option value="Maths">Maths</option>
                        <option value="English">English</option>
                        <option value="VR">VR</option>
                        <option value="NVR">NVR</option>
                    </Form.Select>
                </Form.Group>
                <Form.Group controlId="choice">
                    <Form.Select
                        style={{ fontSize: "14px" }}
                        onChange={(e) => onInputChange(e)}
                        name="choice"
                    >
                        <option value="">Filter Questions</option>
                        <option value="table1">Questions by topic </option>
                        {/* <option value="table2">Questions by difficulty Level </option>
                        <option value="table3">Questions by Academic Year </option>
                        <option value="table4">Questions by Answers with stacked bar graph</option> */}
                    </Form.Select>
                </Form.Group>
            </Row>
            {(MQInput.choice != "" && MQInput.subject != "") &&
                <BootstrapeTable
                    keyField="id"
                    data={sameDatas.length > 0 ? sameDatas : []} // Show empty array if sameDatas is empty
                    columns={columns1}
                    pagination={paginationFactory()}
                    filter={filterFactory()}
                    cellEdit={cellEditFactory({
                        mode: "click",
                        blurToSave: true,
                        type: Type.TEXTAREA,
                    })}
                />
            }
            {sameDatas.length === 0 && <p>No data to display</p>}
        </div>
    )
}
